import { useQuery } from "@apollo/client"
import React from "react"
import { PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables } from "../../../../__generated__/PoolInvitationCenterQuery"
import { TPoolRouteProps } from "../../../../routes.d"
import useControls from "../../../hooks/useControls"

import { POOL_INVITATION_CENTER_QUERY } from "../../queries"
import MultipleEntriesAreaInvitationCenter from "./MultipleEntriesArea/InvitationCenter"

interface IProps {
  isModal?: boolean
  joinKey?: string
  poolUrl?: string
  emailOnly?: boolean
}

const PoolInviteCenter: React.FC<IProps & TPoolRouteProps> = (props) => {
  const { match, poolData } = props
  const { entryId } = poolData
  const sport = match.params.sportType
  const controls = useControls(props)
  const { first, after } = controls
  const variables = { entryId, first, after, sport }
  const skip = !variables.entryId
  const poolInvitationCenterQuery = useQuery<PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables>(POOL_INVITATION_CENTER_QUERY, {
    variables,
    skip,
    ssr: false,
  })
  return <MultipleEntriesAreaInvitationCenter {...props} invitationData={poolInvitationCenterQuery} controls={controls} />
}

export default PoolInviteCenter
