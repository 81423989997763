import React, { FC, useState, ReactText, useRef } from "react"
import { QueryResult } from "@apollo/client"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import LinkIcon from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Link"
import EmailIcon from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Email"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast/toast"
import LoadingView from "@cbs-sports/sports-shared-client/build/cjs/components/LoadingView"
import constants from "../../../../../common/constants"
import { PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables } from "../../../../../__generated__/PoolInvitationCenterQuery"
import { TPoolRouteProps } from "../../../../../routes.d"
import { copyToClipboard, getInvitationUrl, withoutDomain } from "../../../../utils/url-utils"
import { toNodes } from "../../../../utils/misc-utils"
import { StandardPoolPageContainer } from "../../components/PoolPage"
import { InvitationCenterWrapper } from "./InvitationCenter.styles"
import { MultipleEntriesPoolInviteMailCenterModal } from "../MultipleEntriesArea/MultipleEntriesPoolInviteMailCenter"
import AddBracketComponent from "../../components/MultipleEntriesArea/AddBracketComponent"
import InviteAllPreviousPoolMembersModal from "../../components/InviteAllPreviousPoolMembersModal"
import Analytics from "../../../../utils/analytics"
import { IControls } from "../../../../hooks/useControls"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import PaginationNew from "../../components/PaginationNew"
import { Redirect } from "react-router-dom"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import { scrollToTop } from "../../../../components/ScrollToTopOnMount"

interface IHaveInvitationsGraphqlData {
  invitationData: QueryResult<PoolInvitationCenterQuery, PoolInvitationCenterQueryVariables>
  controls: IControls
}

type InvitationCenterProps = TPoolRouteProps & IHaveInvitationsGraphqlData

const PendingInvitations: FC<IHaveInvitationsGraphqlData> = ({ invitationData }) => {
  const pendingInvitationNodes = toNodes(invitationData.data?.entry.sentInvitations)
  const hasPendingInvitations = pendingInvitationNodes.length > 0

  const pendingInvitationNodesContent = pendingInvitationNodes.map((i) => (
    <div key={i.id} className="invitations__row invitations__row--content">
      <div className="invite__text--row-main">{i.foreignId}</div>
    </div>
  ))

  const content = (
    <div className="invitatons__row invitations__row--no-content">
      <div className="invite__text--body1 invite__text--centered main-line" data-cy="invite-friends-text">
        You do not have any outstanding email invites at this time.
      </div>
      <div className="invite__text--body2 invite__text--centered" data-cy="invite-friends-text2">
        When you send email invites directly, you can track the invites you’ve sent here.
        <br />
        It also makes it easy to remind your friends when they’re dragging their feet to join.
      </div>
    </div>
  )
  return (
    <div className="invite__invitations-table">
      <div className="invitations__row">
        <div className="invitations__col--9">
          <div className="invite__text--table-header">Email</div>
        </div>
      </div>
      {hasPendingInvitations ? pendingInvitationNodesContent : content}
    </div>
  )
}

const InvitationCenter: FC<InvitationCenterProps> = (props) => {
  const { poolData } = props
  const { poolDetail, hasManagerRole, currentUser, gameInstanceUid } = poolData
  const { parentPoolId, inviteAllEmailSent } = poolDetail || {}
  const [copyToClipboardToast, setCopyToClipboardToast] = useState<ReactText>("")
  const [emailSentToast, setEmailSendToast] = useState<ReactText>("")
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false)
  const [showPoolInviteEmailModal, setShowPoolInviteEmailModal] = useState<boolean>(false)
  const wrap = useRef<HTMLDivElement | null>(null)
  const [showInvitePoolComponent, setShowInvitePoolComponent] = useState<boolean>(
    hasManagerRole && Boolean(parentPoolId) && !Boolean(inviteAllEmailSent),
  )
  const openEmailModal = () => {
    setIsEmailModalOpen(true)
  }
  const closeEmailModal = () => setIsEmailModalOpen(false)

  const poolUrl = props.poolData.poolDetail?.url ?? ""
  const entryId = props.poolData.entryId
  const pool = props.poolData.poolDetail

  const onGoToPage = (cursor: string | null) => {
    props.controls.goToPage(cursor)
    scrollToTop()
  }

  const handleCopy = (args: any) => {
    inviteMethodClickTrack("copy link")
    const value = getInvitationUrl(args)
    copyToClipboard(value)
    if (!copyToClipboardToast) {
      const toastId = toast.snackbar("Pool invite link copied to clipboard", {
        position: "bottom-center",
        onClose: () => {
          setCopyToClipboardToast("")
        },
      })
      setCopyToClipboardToast(toastId)
    }
  }

  const onEmailSent = () => {
    if (!emailSentToast) {
      const toastId = toast.snackbar("Email invite sent", { position: "bottom-center", onClose: () => setEmailSendToast("") })
      setEmailSendToast(toastId)
    }
  }
  const handleInvitePool = () => {
    setShowPoolInviteEmailModal(true)
  }
  const handleCloseInvitePoolModal = () => {
    setShowPoolInviteEmailModal(false)
  }
  const onInvitePoolEmailSent = () => {
    setShowInvitePoolComponent(false)
    onEmailSent()
  }
  const inviteMethodClickTrack = (location: string) => {
    Analytics.trackAction("pool home", `invite${hasManagerRole ? "" : " - participant "}`, location)
  }
  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid)
  const gender = isWomenBracket ? "Women's" : "Men's"
  const emailDefaultMessage = hasManagerRole
    ? `${gender} Brackets are back and I've started a pool${
        poolDetail?.name ? `, ${poolDetail.name},` : ""
      } on CBSSports.com. Join now before the tournament starts so we can compete all of March Madness.`
    : `My friend is running a ${gender} Tournament Bracket Pool${
        poolDetail?.name ? `, ${poolDetail.name},` : ""
      } on CBS Sports and I want you to join in on the fun. Join now before the tournament starts so we can compete all of March Madness!`
  const emailDefaultSubject = `${currentUser?.preferredEntryName || "Manager"} has invited you to a ${gender} bracket pool!`
  const pageInfo = props.invitationData.data?.entry.sentInvitations?.pageInfo || emptyObject
  const { totalCount } = pageInfo

  const poolUsesOpenInvites =
    (constants.ENABLE_OPEN_INVITES && poolDetail?.poolSettings.__typename === "PoolSettings" && poolDetail.poolSettings.openInvites) || false
  if (!poolUsesOpenInvites && !hasManagerRole) {
    return <Redirect to={`${withoutDomain(poolUrl)}/standings`} />
  }

  return (
    <StandardPoolPageContainer {...props} analyticsFeature="brackets" analyticsSubfeature={`invites`} analyticsTitle={`Invites`}>
      <InvitationCenterWrapper ref={wrap}>
        <div className="invite__title-box">
          <div className="invite__text--title">Invite friends</div>
        </div>
        <div className="invite__info-box">
          <div className="invite__text--body1">Email an invite and track them below.</div>
          <div className="invite__text--body2">You can also invite by copying and pasting your pool's invite URL.</div>
        </div>
        {props.invitationData.loading ? (
          <LoadingView />
        ) : (
          <>
            <div className="invite__actions-box">
              <Button variant="secondary" disabled={!constants.PUBLIC_SMTP_ENABLED} type="button" onClick={openEmailModal}>
                <ListItemIcon alignment="left">
                  <EmailIcon />
                </ListItemIcon>
                Email
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={() => handleCopy({ medium: "copy", pool, entryId, hasManagerRole })}
                data-cy="copy-link"
                style={{ marginLeft: "0.75rem" }}
              >
                <ListItemIcon alignment="left">
                  <LinkIcon />
                </ListItemIcon>
                Copy Link
              </Button>
            </div>
            <div className="invite__pending-title-box">
              <div className="invite__text--subtitle">Pending Invitations</div>
              <div className="invite__text--body3">{`Total: ${totalCount || 0}`}</div>
            </div>
            <div className="invite__pending-invitations">
              <PendingInvitations invitationData={props.invitationData} controls={props.controls} />
            </div>
            <MultipleEntriesPoolInviteMailCenterModal
              {...props}
              isOpen={isEmailModalOpen}
              onClose={closeEmailModal}
              onNotificationSent={onEmailSent}
              defaultMessage={emailDefaultMessage}
              defaultSubject={emailDefaultSubject}
              isPoolPage
            />
          </>
        )}
      </InvitationCenterWrapper>
      {showInvitePoolComponent && <AddBracketComponent variant="invite" purpose="inviteAll" routeProps={props} onClick={handleInvitePool} />}
      <PaginationNew hasPreviousPage={!!props.controls.after} goToPage={onGoToPage} isLoading={props.invitationData.loading} {...pageInfo} />
      <InviteAllPreviousPoolMembersModal
        {...props}
        isOpen={showPoolInviteEmailModal}
        onClose={handleCloseInvitePoolModal}
        onInviteSent={onInvitePoolEmailSent}
      />
    </StandardPoolPageContainer>
  )
}

export default InvitationCenter
