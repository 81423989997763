import React, { useMemo, useCallback } from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"
import Button, { StyledButton } from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import { fontFamily, fontWeight, palette } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import { GameWeightTypeEnum, RoundBonusTypeEnum, RoundModifierTypeEnum } from "../../../../common/enums"
import { getFullPoolSettings } from "../../../../common/pool-settings-setup"
import Link from "../../../components/Link"
import { IUpsertBracketPoolRulesFields, PoolFormRulesFields } from "./BracketPoolFormComponents"
import { withoutDomain } from "../../../utils/url-utils"

const PoolSetupRulesWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .section {
    margin-top: 1rem;
  }

  .title {
    font-family: ${fontFamily.base};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: ${fontWeight.bold};
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: center;
    color: ${palette.gray20};

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    padding: 1rem 0;

    & > :first-child {
      margin-right: 1rem;
    }

    &::before {
      content: "";
      height: 1px;
      background-color: ${palette.gray90};
      width: calc(100% + 3rem);
      left: -1.5rem;
      top: 0;
      position: absolute;
    }
  }
`

const PoolSetupRules = (props) => {
  const { pool, subtitle, switchStep, mutation } = props

  const initialValues = useMemo<IUpsertBracketPoolRulesFields>(() => {
    const rulesValues = getFullPoolSettings(pool)
    const rb = rulesValues.roundBonuses || []
    if (!rb.length) {
      rulesValues.roundBonuses = getFullPoolSettings(pool, true).roundBonuses
    }
    const { roundBonuses, roundModifiers, maxEntriesPerUser, mainTiebreaker, includeMessageBoard } = rulesValues
    const computedRoundModifiers = roundModifiers ?? (roundBonuses ?? []).map(() => RoundModifierTypeEnum.NONE)
    return {
      seasonId: pool.season.id,
      constitution: pool.constitution,
      poolId: pool.id,
      poolSettings: {
        roundBonuses,
        roundModifiers: computedRoundModifiers as RoundModifierTypeEnum[],
        maxEntriesPerUser,
        mainTiebreaker,
        includeMessageBoard,
      },
    }
  }, [pool])

  const handleSubmit = useCallback(
    (values: IUpsertBracketPoolRulesFields) => {
      const updatedPoolSettings: any = { ...values.poolSettings }
      const currentPoolSettings = getFullPoolSettings(pool)
      if (currentPoolSettings.gameWeightType !== GameWeightTypeEnum.ROUND) {
        updatedPoolSettings.gameWeightType = GameWeightTypeEnum.ROUND
      }
      if (currentPoolSettings.roundBonusType !== RoundBonusTypeEnum.STANDARD) {
        updatedPoolSettings.roundBonusType = RoundBonusTypeEnum.STANDARD
      }

      const variables = {
        poolId: pool.id,
        seasonId: pool.season.id,
        poolSettings: updatedPoolSettings,
        constitution: values.constitution,
        usesMagicLink: pool.usesMagicLink,
      }
      return mutation({ variables })
        .then(() => {
          switchStep("invite", pool.id)
        })
        .catch((error) => console.log(error))
    },
    [mutation, pool, switchStep],
  )
  const { url } = pool
  const poolUrl = (url && `${withoutDomain(url)}/standings`) || "/"

  return (
    <PoolSetupRulesWrap>
      <span className="title" data-cy="create-pool-label-one">
        Congratulations, your pool has been created!
      </span>
      <span className="title" data-cy="create-pool-label-two">
        {subtitle}
      </span>

      <div className="section">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(formik) => {
            return (
              <Form>
                <PoolFormRulesFields formik={formik} pool={pool} variant="fullWidth" />
                <div className="actions">
                  <StyledButton className="variant--secondary size--md" as={Link} to={poolUrl} data-cy="cancel-btn">
                    Cancel
                  </StyledButton>
                  <Button variant="primary" type="submit" withLoading loading={formik.isSubmitting} data-cy="save-continue-btn">
                    Save &amp; Continue
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </PoolSetupRulesWrap>
  )
}

export default PoolSetupRules
